import './aboutUsDetailInfo.css'
import React, { useEffect } from 'react'
import UseTech from './UseTech/UseTech'
import { useLocation, useNavigate } from 'react-router';
import { BsArrowRight } from "react-icons/bs";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

function AboutUsDetailInfo() {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({ behavior: "smooth" })
            }
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        }
    }, [location,]);

    return (
        <div className='mt-5'>
            <div className='container'>
                <div className="about-detail-info" id='about-detail-info'
                    data-aos="fade-up"
                    data-aos-offset="100"
                    data-aos-anchor-placement="top-bottom">
                    <div className="row d-flex justify-content-center">
                        <div className="col-sm-12 text-center"><h1 className='display-4 mt-lg-5 mb-4'>{t('AboutUsDetail.about_us')}</h1>
                            <p className='fs-4 fw-lighter mb-4 fst-italic'>{t('AboutUsDetail.description')}</p>
                        </div>
                    </div>
                </div>
                <div className="row mb-5 align-items-center"
                    data-aos="fade-right"
                    data-aos-offset="100"
                    data-aos-anchor-placement="top-bottom">
                    <div className="col-xxl-4 col-xl-6 mb-5 d-flex justify-content-center">
                        <div className="card-mission p-3">
                            <div className='img-mission'>
                                <img src={require('../../../assets/images/about/mission1.png')} alt="" />
                            </div>
                            <div className='content-mission'>
                                <h1 className='fs-4'>{t('AboutUsDetail.our_goal')}</h1>
                                <p className='lead text-muted fs-6'> {t('AboutUsDetail.our_goal_text')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-4 col-xl-6 d-flex justify-content-center mb-5">
                        <div className="card-mission p-3 height_mission">
                            <div className='img-mission'>
                                <img src={require('../../../assets/images/about/mission2.png')} alt="" />
                            </div>
                            <div className='content-mission'>
                                <h1 className='fs-4'>{t('AboutUsDetail.our_mission')}</h1>
                                <p className='lead text-muted fs-6'> {t('AboutUsDetail.our_mission_text')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-4 col-xl-12 d-flex justify-content-center mb-5">
                        <div className="card-mission p-3">
                            <div className='img-mission'>
                                <img src={require('../../../assets/images/about/mission3.png')} alt="" />
                            </div>
                            <div className='content-mission'>
                                <h1 className='fs-4'>{t('AboutUsDetail.our_values')}</h1>
                                <p className='lead text-muted fs-6'>{t('AboutUsDetail.our_values_text')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row w-100">
                    <div className="col">
                    </div>
                </div>
            </div>
            <UseTech />
            <div className='vacancy-section_1 text-white' id='vacancy'>
                <div className='container height display-fl-mb'>
                    <div className="row content-vacancy align-items-sm-center">
                        <div className="col-md-6">
                            <h1 className='display-5 mb-4'>{t('AboutUsDetail.join_our_team')}</h1>
                        </div>
                        <div className="col-md-5 ms-sm-5 d-flex justify-content-sm-end jc-ct-mb">
                            <button className='btn-vacancy ms-sm-5'
                                onClick={() => navigate('../jobs')}>{t('AboutUsDetail.btn_vacancy')}<BsArrowRight className='ms-2' />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* крата мир */}
            <div className='container '>
                <div className='filials'>
                    <div className="row">

                        <h1 className='display-6 ps-5 pt-5 ps-sm-0'>{t('AboutUsDetail.our_branches')}</h1>
                        <div className="col-lg-3 ps-5 ps-sm-0">
                            <svg width="0" height="0">
                                <linearGradient id="blue-gradient" x1="100%" y1="50%" x2="0%" y2="0%">
                                    <stop stopColor="#A74AE7" offset="0%" />
                                    <stop stopColor="#0085FF" offset="100%" />
                                </linearGradient>
                            </svg>
                            <div className='mb-5'>
                                <div className='d-flex align-items-center'>
                                    <FaMapMarkerAlt size={30} style={{ fill: "url(#blue-gradient)" }} />
                                    <h5 className='h5 m-0 ms-3'>{t('AboutUsDetail.germany')}</h5>
                                </div>
                                <p className='fs-5 m-3'>{t('AboutUsDetail.germany_city')}</p>
                            </div>
                            <div className='mb-5'>
                                <div className='d-flex align-items-center'>
                                    <FaMapMarkerAlt size={30} style={{ fill: "url(#blue-gradient)" }} />
                                    <h5 className='h5 m-0 ms-3'>{t('AboutUsDetail.tajikistan')}</h5>
                                </div>
                                <p className='fs-5 m-3'>{t('AboutUsDetail.tajikistan_city_1')}</p>
                                <p className='fs-5 m-3'>{t('AboutUsDetail.tajikistan_city_2')}</p>
                            </div>
                            <div className='mb-5'>
                                <div className='d-flex align-items-center'>
                                    <FaMapMarkerAlt size={30} style={{ fill: "url(#blue-gradient)" }} />
                                    <h5 className='h5 m-0 ms-3'>{t('AboutUsDetail.uzbekistan')}</h5>
                                </div>
                                <p className='fs-5 m-3'>{t('AboutUsDetail.uzbekistan_city')}</p>
                            </div>
                            <div>
                                <div className='d-flex align-items-center'>
                                    <FaMapMarkerAlt size={30} style={{ fill: "url(#blue-gradient)" }} />
                                    <h5 className='h5 m-0 ms-3'>{t('AboutUsDetail.turkey')}</h5>
                                </div>
                                <p className='fs-5 m-3'>{t('AboutUsDetail.turkey_city')}</p>
                            </div>
                        </div>
                        <div
                            className="col-lg-9 order-first order-lg-0 d-flex justify-content-center position-relative">

                            <img className='img-fluid' src={require('../../../assets/images/about/world.png')}
                                alt="img" />
                            <div className='german-laction'>
                                <img className='img-fluid' src={require('../../../assets/images/about/location.png')}
                                    alt="img" />
                                <span className="tooltiptext">{t('AboutUsDetail.germany')}</span>
                            </div>
                            <div className='turkey-laction cursor-pointer'>
                                <img className='img-fluid' data-bs-toggle="tooltip" data-bs-placement="top"
                                    title="Tooltip on top" src={require('../../../assets/images/about/location.png')}
                                    alt="img" />
                                <span className="tooltiptext">{t('AboutUsDetail.turkey')}</span>
                            </div>
                            <div className='tajikistan-laction'>
                                <span className="tooltiptext">{t('AboutUsDetail.tajikistan')}</span>
                                <img className='img-fluid' src={require('../../../assets/images/about/location.png')}
                                    alt="img" />
                            </div>
                            <div className='uzbakistan-laction cursor-pointer'>
                                <img className='img-fluid' 
                                    title="Tooltip on top" src={require('../../../assets/images/about/location.png')}
                                    alt="img" />
                                <span className="tooltiptext">{t('AboutUsDetail.uzbekistan')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsDetailInfo
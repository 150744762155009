import React from 'react'
import { BrowserRouter as Router, useNavigate, useRoutes } from "react-router-dom";
import Main from "./components/Main/Main";
import AllVacancies from "./components/Vacancy/AllVacancies/AllVacancies";
import RespondVacancy from "./components/Vacancy/RespondVacancy/RespondVacancy";
import Navbar from "./components/Navbar/Navbar";
import About from "./components/About/About";
import Vacancy from './components/Vacancy/Vacancy';
import OurProjects from "./components/OurProjects/OurProjects";
import Feedback from "./components/Feedback/Feedback";
import Contact from "./components/Contact/Contact";
import NumbersSpeak from "./components/NumbersSpeak/NumbersSpeak";
import { useEffect, useState } from "react";
import ExperiencedTeam from "./components/ExperiencedTeam/ExperiencedTeam";
import AboutUsDetailInfo from "./components/About/AboutUsDetailInfo/AboutUsDetailInfo";
import PrivatePolicy from "./components/Feedback/PrivePolicy";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";

function Routes() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const route = useRoutes([
    {
      path: '/',
      element: <><Navbar />
        <CookieConsent
          cookieName="AttoLabsCookies"
          location="bottom"
          style={{ background: '#222', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          buttonStyle={{ color: "#000", background: '#fff', fontSize: '14px', borderRadius: '10px', width: '100px' }}
          buttonText={t('Cookies.agree')}
          expires={150}
          contentStyle={{ flex: 'auto', margin: '20px', align: 'center' }}
        >
          {t('Cookies.this_site')} <a className="privacy-policy" onClick={() => navigate('private-policy')}>{t('Cookies.policy_cookies')}</a>
        </CookieConsent></>,
      children: [
        { path: '/', element: <><Main />  <About />   <OurProjects /> <NumbersSpeak /> <ExperiencedTeam />  <Vacancy /> <Feedback /><Contact /></> },
        { path: "/jobs", element: <><AllVacancies /><Contact /></> },
        { path: '/jobs/:codeVacancy', element: <><RespondVacancy /><Contact /></> },
        { path: '/private-policy', element: <><PrivatePolicy /><Contact /></> },
        { path: '/about-us-detail', element: <><AboutUsDetailInfo /><Contact /></> },
      ],
    },
  ]);
  return route;
}

export default Routes;

import './useTech.css'
import React from 'react'
import { useTranslation } from 'react-i18next';

function UseTech() {
  const { t } = useTranslation()
  return (
    <div className='section-use-tech' id='use-techs'  >
      <h1 className='display-6 text-center mb-4 p-4'>{t('AboutUsDetail.technologies_are_used')}</h1>
      <div class="scroll_bg" id="scroll_bg">
        <div class="img">
          <div className='d-flex justify-content-center align-items-center' style={{ padding: "10px" }}>
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech1.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech2.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech3.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech4.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech5.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech6.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech7.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech8.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech9.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech10.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech11.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech12.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech13.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech14.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech15.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech16.png')} alt="img" />

            <img className='img-use-tech ' style={{ height: '65px' }} src={require('../../../../assets/images/about/use-technalogy/tech17.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '100px' }} src={require('../../../../assets/images/about/use-technalogy/tech18.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '75px' }} src={require('../../../../assets/images/about/use-technalogy/tech19.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '65px' }} src={require('../../../../assets/images/about/use-technalogy/tech20.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '90px' }} src={require('../../../../assets/images/about/use-technalogy/tech21.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '85px' }} src={require('../../../../assets/images/about/use-technalogy/tech22.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '105px' }} src={require('../../../../assets/images/about/use-technalogy/tech23.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '85px' }} src={require('../../../../assets/images/about/use-technalogy/tech24.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '65px' }} src={require('../../../../assets/images/about/use-technalogy/tech25.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '65px' }} src={require('../../../../assets/images/about/use-technalogy/tech26.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '65px' }} src={require('../../../../assets/images/about/use-technalogy/tech27.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '55px' }} src={require('../../../../assets/images/about/use-technalogy/tech28.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '95px' }} src={require('../../../../assets/images/about/use-technalogy/tech29.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '45px' }} src={require('../../../../assets/images/about/use-technalogy/tech30.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '60px' }} src={require('../../../../assets/images/about/use-technalogy/tech31.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '55px' }} src={require('../../../../assets/images/about/use-technalogy/tech32.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '65px' }} src={require('../../../../assets/images/about/use-technalogy/tech33.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '65px' }} src={require('../../../../assets/images/about/use-technalogy/tech34.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '65px' }} src={require('../../../../assets/images/about/use-technalogy/tech35.png')} alt="img" />
          </div>

        </div>
        <div class="img ms-2">
          <div className=' d-flex justify-content-center align-items-center'>

            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech1.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech2.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech3.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech4.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech5.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech6.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech7.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech8.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech9.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech10.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech11.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech12.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech13.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech14.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech15.png')} alt="img" />
            <img className='img-use-tech ' src={require('../../../../assets/images/about/use-technalogy/tech16.png')} alt="img" />

            <img className='img-use-tech ' style={{ height: '65px' }} src={require('../../../../assets/images/about/use-technalogy/tech17.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '100px' }} src={require('../../../../assets/images/about/use-technalogy/tech18.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '75px' }} src={require('../../../../assets/images/about/use-technalogy/tech19.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '65px' }} src={require('../../../../assets/images/about/use-technalogy/tech20.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '90px' }} src={require('../../../../assets/images/about/use-technalogy/tech21.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '85px' }} src={require('../../../../assets/images/about/use-technalogy/tech22.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '105px' }} src={require('../../../../assets/images/about/use-technalogy/tech23.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '85px' }} src={require('../../../../assets/images/about/use-technalogy/tech24.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '65px' }} src={require('../../../../assets/images/about/use-technalogy/tech25.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '65px' }} src={require('../../../../assets/images/about/use-technalogy/tech26.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '65px' }} src={require('../../../../assets/images/about/use-technalogy/tech27.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '55px' }} src={require('../../../../assets/images/about/use-technalogy/tech28.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '95px' }} src={require('../../../../assets/images/about/use-technalogy/tech29.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '45px' }} src={require('../../../../assets/images/about/use-technalogy/tech30.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '60px' }} src={require('../../../../assets/images/about/use-technalogy/tech31.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '55px' }} src={require('../../../../assets/images/about/use-technalogy/tech32.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '65px' }} src={require('../../../../assets/images/about/use-technalogy/tech33.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '65px' }} src={require('../../../../assets/images/about/use-technalogy/tech34.png')} alt="img" />
            <img className='img-use-tech ' style={{ height: '65px' }} src={require('../../../../assets/images/about/use-technalogy/tech35.png')} alt="img" />
          </div>

        </div>
      </div>
    </div>
  )
}

export default UseTech
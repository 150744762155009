import './NumbersSpeak.css'
import React from 'react'
import CountUp from 'react-countup'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'


function NumbersSpeak() {
    const { ref, inView } = useInView({
        threshold: 0.5
    })
    const { t } = useTranslation();
    return (
        <div className='number-speak-section'>
            <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-3 text-center text-md-start">
                            <h1 className='display-6'>{t('NumberSpeak.numbers_speak_for_us')} </h1>
                        </div>

                        <div className="col-md-3 text-center text-md-start d-flex justify-content-center mt-5 mt-lg-0" data-aos="zoom-in-up">
                            <div className="col-10 col-sm-10 number-hover">
                                <h1 ref={ref} className='display-6 fs-2'>{inView && <CountUp start={0} end={20} duration={2} delay={0} />}+</h1>
                                <h1 className='display-6 fs-4'>{t('NumberSpeak.projects')}</h1>
                            </div>
                        </div>

                        <div className="col-md-3 text-center text-md-start d-flex justify-content-center  mt-5 mt-lg-0" data-aos="zoom-in-up">
                            <div className="col-10 col-md-12 col-lg-10 number-hover">
                                <h1 ref={ref} className='display-6 fs-2'>{inView && <CountUp start={0} end={50} duration={2} delay={0} />}+</h1>
                                <h3 className='display-6 fs-4'>{t('NumberSpeak.employees')}</h3>
                            </div>
                        </div>

                        <div className="col-md-3 text-center text-md-start  d-flex justify-content-center mt-5 mt-lg-0" data-aos="zoom-in-up">
                            <div className="col-10 col-sm-10 number-hover">
                                <h1 ref={ref} className='display-6 fs-2'>{inView && <CountUp start={0} end={50} duration={2} delay={0} />}+</h1>
                                <h3 className='display-6 fs-4'>{t('NumberSpeak.services')}</h3>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default NumbersSpeak

import axios from 'axios';

const apiUrl = 'https://backend.attolabs.tj/api/site';

export const getLanguages = async (resource, params) => {
    return axios.get(`${apiUrl}/${resource}`
    ).then((response) => response.data)
        .catch((error) => {
            console.log(error);
        });;
}

export const getAllProjects = async (resource, params) => {
    return axios.get(`${apiUrl}/${resource}`, {
        params: {
            langId: params.langId
        }
    }
    ).then((response) => response.data)
        .catch((error) => {
            console.log(error);
        });;
};

export const getVacancies = async (resource, params) => {
    return axios.get(`${apiUrl}/${resource}`, {
        params: {
            countrycode: params.countyCode,
            langId: params.langId
        }
    }
    ).then((response) => response.data)
        .catch((error) => {
            console.log(error);
        });;
}

export const getGeoInfo = async () => {
    return await axios.get('https://ipapi.co/json/')
        .then((response) => response.data)
        .catch((error) => {
            console.log(error);
        });
}

export const getGroups = async (resource, params) => {
    return axios.get(`${apiUrl}/${resource}`, {
        params: {
            langId: params.langId
        }
    }
    ).then((response) => response.data)
        .catch((error) => {
            console.log(error);
        });
}

export const getCounrties = async (resource, params) => {
    return axios.get(`${apiUrl}/${resource}`, {
        params: {
            langId: params.langId
        }
    }
    ).then((response) => response.data)
        .catch((error) => {
            console.log(error);
        });
}

export const getCities = async (resource, params) => {
    return axios.post(`${apiUrl}/${resource}`, {
        countryId: params.selectCountryId
    }
    ).then((response) => response.data)
        .catch((error) => {
            console.log(error);
        });
}

export const getFilterVacancies = async (resource, params) => {
    return axios.post(`${apiUrl}/${resource}`, {
        vacancyCityId: params.cityId,
        vacancyCountryId: params.countyId,
        vacancyGroupId: params.groupId,
        page: params.pageApi,
        size: params.size,
        langId: params.langId
    }
    ).then((response) => response.data)
        .catch((error) => {
            console.log(error);
        });
}

export const sendFeedBack = async (resource, params) => {
    return axios.post(`${apiUrl}/${resource}`, {
        fullName: params.data.fullname,
        phone: params.data.phone,
        email: params.data.email,
        message: params.data.message,
        langId: params.langId,
        lang: params.lang
    }
    ).then((response) => response.data)
        .catch((error) => {
            console.log(error);
        });
}

export const sendVacancyAplication = async (resource, params) => {
    return axios({
        method: "post",
        url: `${apiUrl}/${resource}`,
        data: params.formData,
        headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => response.data)
        .catch((error) => {
            console.log(error);
        })
}

export const getVacancyByCode = async (resource, params) => {
    return axios.post(`${apiUrl}/${resource}`, {
        codeVacancy: params.codeVacancy,
        langId: params.langId
    }
    ).then((response) => response.data)
        .catch((error) => {
            console.log(error);
        });
}

import './About.css'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

function About() {
    const navigate = useNavigate();
    const { t } = useTranslation()


    return (
        <div className='about-section'>
            <div className="container" id='about'
                data-aos="fade-up">
                <div className="row about-content h-100 d-flex align-items-center">
                    <div className="col-sm-12 text-center">
                        <h1 className='display-5 mb-4'>{t('About.who_are_we')}</h1>
                        <p className='lead text-muted fs-6 lh-lg'>{t('About.description')}</p>
                        <button className='btn-about' onClick={() => navigate('about-us-detail')}><p
                            className='m-0'>{t('About.btn_more')}<BsArrowRight className='ms-2 to-hover' />
                        </p></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
import './App.css';
import { useEffect, useState } from "react";
import Routes from "./Routes";
import { useLocation } from 'react-router';

function App({ hideLoader }) {

  useEffect(hideLoader, []);
  const { pathname, hash, key } = useLocation();
  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); 

  return (
    <Routes/>
  );
}

export default App;

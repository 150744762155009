import './feedback.css'
import React, { useEffect, useState } from 'react'
import { BsFacebook, BsLinkedin, BsInstagram } from "react-icons/bs";
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import i18n from 'i18next';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { TextField, Typography, Button } from '@mui/material';
import Cookies from 'js-cookie';
import { useGlobalState } from '../state';
import { sendFeedBack } from '../dataProvider';


function Feedback() {
    const navigate = useNavigate();
    const cookie = Cookies.get('AttoLabsCookies');
    const alert = useAlert();
    const lang = i18n.language;
    const [langId] = useGlobalState("langId");
    const { t } = useTranslation();
    const [isPending, setisPending] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isChecked, setChecked] = useState(false);

    const validationSchema = Yup.object().shape({
        fullname: Yup.string()
            .required(t('ErrorForm.required_field'))
            .matches(/^[aA-zZ-аА-яЯ]*$/, t('ErrorForm.only_alphabet'))
            .min(3, t('ErrorForm.name_must_be_3_more'))
            .max(20, t('ErrorForm.name_must_be_20_more')),
        phone: Yup.string()
            .required(t('ErrorForm.required_field'))
            .min(9, t('ErrorForm.phone_must_be_9_more'))
            .max(15, t('ErrorForm.phone_must_be_15_more')),
        email: Yup.string()
            .required(t('ErrorForm.required_field'))
            .email(t('ErrorForm.incorrect_email')),
        message: Yup.string()
            .required(t('ErrorForm.required_field'))
            .max(100, t('ErrorForm.message_must_be_100_more')),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        reset
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
        clearErrors();
    }, [lang]);

    const onSubmit = async (data) => {
        setisPending(true);
        await sendFeedBack("feedback", { data, langId, lang }).then((res) => {
            reset();
            setisPending(false);
            onCheckboxClick();
            console.log(1)
            alert.show(t('Alert.form_sent'), {
                type: 'success',
                position: cookie ? 'bottom center' : 'top center'
            })
        }).catch(() => {
            console.log(3)
            alert.show(t('Alert.server_error'), {
                type: 'error',
            });
            reset();
            setisPending(false);
            onCheckboxClick();
        });

    }

    // check box check
    const Igree = () => {
        return isChecked ? setIsDisabled(true) : setIsDisabled(false);
    };
    const onCheckboxClick = () => {
        setChecked(!isChecked);
        return Igree();
    };

    return (
        <div className='feedback-section' id='feedback'>
            <div className='container'>
                <div className='feedback-from'>
                    <div className="row">
                        <div className="col-xxl-3">
                            <div
                                className='contact-us text-white p-4 d-flex flex-column justify-content-between align-items-start align-self-stretch'>
                                <div className='mt-3'>
                                    <h1 className='display-6'>{t('Feedback.contact_us')}</h1>
                                    <p className='fw-light fst-italic m-0 small'>{t('Feedback.we_will_respond')}</p>
                                </div>
                                <div className='d-none d-xxl-block mb-3'>
                                    <p className={'fs-5'}>{t('Feedback.social_networks')}</p>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/attolabs.de/"><BsFacebook size={20} className='ms-1 social-icon' /></a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/attolabs/"><BsLinkedin size={20} className='ms-3 social-icon' /></a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/attolabs/"><BsInstagram size={20} className='ms-3 social-icon' /></a>
                                </div>
                            </div>
                        </div>

                        <form className="col-xxl-9 mt-5" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-lg-6 d-flex align-items-center flex-column ">
                                    <div className='input-style-div'>
                                        <div>
                                            <TextField
                                                className='input-style'
                                                variant='standard'
                                                id="fullname"
                                                name="fullname"
                                                label={t('Feedback.placeholder_your_name')}
                                                margin="dense"
                                                {...register('fullname')}
                                                error={errors.fullname ? true : false}
                                            />
                                        </div>
                                        <div>
                                            <Typography sx={{ fontSize: '13px', color: 'red' }}>
                                                {errors.fullname?.message}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 d-flex align-items-center flex-column ">
                                    <div className='input-style-div'>
                                        <TextField
                                            className='input-style'
                                            variant='standard'
                                            type="number"
                                            id="phone"
                                            name="phone"
                                            label={t('Feedback.placeholder_tel')}
                                            {...register('phone')}
                                            error={errors.phone ? true : false}
                                        />
                                        <Typography sx={{ fontSize: '13px', color: 'red' }}>
                                            {errors.phone?.message}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 d-flex align-items-center flex-column ">
                                    <div className='input-style-div'>
                                        <TextField
                                            className='input-style'
                                            variant='standard'
                                            id="email"
                                            name="email"
                                            label={t('Feedback.placeholder_your_email')}
                                            margin="dense"
                                            {...register('email')}
                                            error={errors.email ? true : false}
                                        />
                                        <Typography sx={{ fontSize: '13px', color: 'red' }}>
                                            {errors.email?.message}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="col-lg-6 d-flex align-items-center flex-column ">
                                    <div className='input-style-div'>
                                        <TextField
                                            className='input-style'
                                            variant='standard'
                                            id="message"
                                            name="message"
                                            label={t('Feedback.placeholder_your_message')}
                                            margin="dense"
                                            {...register('message')}
                                            error={errors.message ? true : false}
                                        />
                                        <Typography sx={{ fontSize: '13px', color: 'red' }}>
                                            {errors.message?.message}
                                        </Typography>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center justify-content-xxl-start">
                                <div className="col-12 col-xl-6 ms-xl-5 d-flex flex-column  ">
                                    <div className='check-apply text-center text-xl-start'>
                                        <label className="custom-checkbox">
                                            <input type="checkbox" onChange={onCheckboxClick}
                                                checked={isChecked} />
                                            <span className='justify-content-center'><Trans i18nKey="Feedback.i_agree">
                                                <a className="privacy-policy" onClick={() => navigate('private-policy')}></a>
                                            </Trans></span>
                                        </label>
                                    </div>
                                    <div className="mb-5 mb-xxl-0 text-center text-xxl-start">
                                        {!isPending &&
                                            <button disabled={isDisabled} className="btn-submit" id="button"><p
                                                className='m-0'>{t('Feedback.send')}</p></button>}
                                        {isPending &&
                                            <button disabled={isDisabled} className="btn-submit" id="button" ><p
                                                className='m-0'> {t('Feedback.loading')}</p></button>}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feedback
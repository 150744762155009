import './experiencedTeam.css'
import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup'
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';


function ExperiencedTeam() {
    const { ref, inView } = useInView({
        threshold: 0.5
    })
    const { t } = useTranslation()

    return (
        <div className='container '>
            <div className="row experiencedTeam">
                <div className="col-lg-6 mt-5">
                    <h1 className='display-5'>{t('ExperiencedTeam.experienced_team')}</h1>
                    <p className='fs-6 w-sm-75 lead text-muted'>{t('ExperiencedTeam.description')}</p>
                </div>

                <div className="col-lg-6">
                    <div className="skill-bars">
                        <div className="bar">
                            <div className="d-flex justify-content-between">
                                <p className={'fs-6'}>{t('ExperiencedTeam.software_development')}</p>
                                <span ref={ref} className='text-muted fs-6'>{inView &&
                                    <CountUp start={0} end={98} duration={2} delay={0} />}%</span>
                            </div>
                            <div className="progress-line html">
                                <span className={inView ? 'active' : ''}></span>
                            </div>
                        </div>
                        <div className="bar">
                            <div className="d-flex justify-content-between">
                                <p className={'fs-6'}>{t('ExperiencedTeam.object_oriented_programming')}</p>
                                <span ref={ref} className='text-muted fs-6'>{inView &&
                                    <CountUp start={0} end={92} duration={2} delay={0} />}%</span>
                            </div>
                            <div className="progress-line css">
                                <span className={inView ? 'active' : ''}></span>
                            </div>
                        </div>
                        <div className="bar">
                            <div className="d-flex justify-content-between">
                                <p className={'fs-6'}> {t('ExperiencedTeam.mobile_development')}</p>
                                <span ref={ref} className='text-muted fs-6'>{inView &&
                                    <CountUp start={0} end={87} duration={2} delay={0} />}%</span>
                            </div>
                            <div className="progress-line jquery">
                                <span className={inView ? 'active' : ''}></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExperiencedTeam
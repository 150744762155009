import './our-project.css'
import { useEffect, useRef, useRefs, useState } from 'react';
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import * as icons from 'react-icons/di'
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useGlobalState } from '../state';
import { getAllProjects } from '../dataProvider';
function OurProjects() {
    const projectContent = useRef();
    const divRef = useRef([]);
    const pRef = useRef([]);
    const { t } = useTranslation();
    const [projects, setProjects] = useState([]);
    const lang = i18n.language;
    const [langId] = useGlobalState("langId");
    const slideLeft = () => {
        const slider = document.getElementById('pro');
        slider.scrollLeft = slider.scrollLeft - projectContent.current.offsetWidth;
    }
    const slideRight = () => {
        const slider = document.getElementById('pro');
        slider.scrollLeft = slider.scrollLeft + projectContent.current.offsetWidth;
    }
    useEffect(() => {
        getAllProjects("projects", { langId }).then((res) => {
            setProjects(res);
        })
    }, [lang]);

    const styleDescrip = {
        parentDescrip: {
            height: "55%",
            overflow: "hidden"
        },

        childDescrip: {
            height: "100%",
            padding: "0px 10px",
            display: "-moz-box",
            lineHeight: "123%",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "center",
            WebkitBoxPack: "center",
            fontSize: "18px",
            lineclamp: "6"
        }
    }

    const project = projects && projects.map((item, index) => {
        if (item.image !== 'undefined.png') {
            return (
                <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 col-xxl-3 p-1-local" key={index} ref={projectContent}>
                    <div className="project-info w-100 h-100">
                        {item.image && <img src={item.image} alt="img project" className='img-project' />}
                        <div className="liquid-row-overlay bg-fade-dark-06"></div>
                        <div className="position-absolute bottom-0 start-0 text-white m-4 ">
                            <p className={'fs-3'}>{item.name}</p>
                            <p className='fs-6 fst-normal line-max-short-description'>{item.shortDescription}</p>
                        </div>
                        <div className="caption">
                            <div className="content">
                                <div className=' d-flex flex-column justify-content-center h-100 gap-3'>
                                    <p className='fs-3 m-0'>{item.name}</p>
                                    <div className='' ref={el => divRef.current[index] = el} style={styleDescrip.parentDescrip}>
                                        <p className='fw-lighter m-0' ref={el => pRef.current[index] = el} style={styleDescrip.childDescrip}>{item.description}</p>
                                    </div>
                                    <div className=''>
                                        <p className='fs-6 mt-0'>{t('Projects.technologies_are_used')}</p>
                                        <div className='use-tech gap-2'>
                                            {item.technology.split(',').map((el, index) => {
                                                const Icon = icons[el]
                                                return (
                                                    <span key={index}>
                                                        <Icon size={30} className='text-white' />
                                                    </span>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    });

    const getHeight = () => {
        let parent;
        let child;
        let calc;

        if (project.length > 1) {
            divRef && divRef.current.forEach((el, indx) => {
                parent = parseFloat(window.getComputedStyle(el).height);
                child = parseFloat(window.getComputedStyle(pRef.current[indx]).lineHeight);
                calc = parseInt(parent / child);
                pRef.current[indx].style.WebkitLineClamp = calc;
            })
        }
    };

    useEffect(() => {
        getHeight();
        // window.addEventListener('resize', getHeight);
    }, [project]);


    return (
        <div className='section-our-project' id='projects'>
            <div className="container-fluid">
                <div className="row">
                    <h1 className='display-5 text-center'>{t('Projects.our_projects')}</h1>
                </div>
                <div className="row p-0">
                    <div className="position-relative p-0">
                        <div className='wrapper-project d-flex' id='pro'>
                            {project}
                            <BsChevronLeft className="bnt-left-scroll" size="40px" onClick={slideLeft} />
                        </div>
                        <div className="position-absolute top-50 start-100 translate-middle"><BsChevronRight
                            className="bnt-raight-scroll" size="40px" onClick={slideRight} /></div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default OurProjects
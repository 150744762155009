import './allVacancies.css'
import React, { useRef } from 'react'
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { AiOutlineShareAlt } from "react-icons/ai";
import { Autocomplete, Box, Chip, IconButton, MenuItem, Pagination, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    FacebookShareButton,
    TelegramShareButton,
    LinkedinShareButton,
} from "react-share";
import icon_copy from '../RespondVacancy/copy-link.svg';
import { BsFacebook, BsTelegram, BsLinkedin } from "react-icons/bs";
import Cookies from 'js-cookie';
import i18n from 'i18next';
import { useGlobalState } from '../../state';
import { getCities, getCounrties, getFilterVacancies, getGroups } from '../../dataProvider';

function AllVacancies(props) {
    const { t } = useTranslation();
    const cookie = Cookies.get('AttoLabsCookies');
    const [pageApi, setPageApi] = useState(1);
    const alert = useAlert()
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({ behavior: "smooth" })
            }
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        }
    }, [location,])

    const [Vacancies, setVacancies] = useState({
        countVacancy: 0,
        allvacancy: []
    });
    const [groupId, setGroupId] = useState(null);
    const [countyId, setCountyId] = useState(null);
    const [cityId, setCityId] = useState(null);
    const [groups, setGroups] = useState([]);
    const [county, setCounty] = useState([]);
    const [city, setCity] = useState([]);
    const [loading, setLoading] = useState(true);
    const [size, setSize] = useState(5);
    const [take, setTake] = useState(false);
    const lang = i18n.language;
    const [langId] = useGlobalState("langId");

    const [selectedOptionsGroup, setSelectedOptionsGroup] = useState([]);
    const handleChangeGroup = (event, value) => {
        setSelectedOptionsGroup(value);
        setGroupId(value.map(el => el.id));
        console.log(groupId);
    }
    const [selectedOptionsCountry, setSelectedOptionsCountry] = useState([]);
    const handleChangeCountry = (event, value) => {
        setSelectedOptionsCountry(value);
        setCountyId(value.map(el => el.id))
    }
    const [selectedOptionsCity, setSelectedOptionsCity] = useState([]);
    const handleChangeCity = (event, value) => {
        setSelectedOptionsCity(value);
        setCityId(value.map(el => el.id))
    }

    useEffect(() => {
        getGroups("groups", { langId }).then((res) => setGroups(res));
        getCounrties("countries", { langId }).then((res) => setCounty(res));
        setSelectedOptionsGroup([]);
        setSelectedOptionsCountry([]);
        setSelectedOptionsCity([]);
    }, [lang]);

   
    useEffect(() => {
        var selectCountryId = selectedOptionsCountry.length > 0 && selectedOptionsCountry.map(el => el.id);
        if (selectCountryId)
            getCities("cities", { selectCountryId }).then((res) => setCity(res));
    }, [county, countyId, lang]);

    useEffect(() => {
        setLoading(true)
        getFilterVacancies("filter-vacancies", { groupId, countyId, cityId, pageApi, size, langId })
            .then((res) => {
                setVacancies({
                    countVacancy: res.countVacancy,
                    allvacancy: res.responses
                })
            }).finally(() => {
                setLoading(false)
            });
    }, [groupId, countyId, cityId, pageApi, size, lang]);


    useEffect(() => {
        setPageApi(1);
    }, [groupId, countyId, cityId]);

    useEffect(() => {
        if (parseInt(Vacancies.countVacancy) > 5)
            setTake(false);
        else
            setTake(true);
    }, [Vacancies.countVacancy]);


    const loadred = (
        <div className="spinner">
            <div className="circle one"></div>
            <div className="circle two"></div>
            <div className="circle three"></div>
        </div>
    );
    const copyCliboard = (e, id) => {
        e.preventDefault();
        navigator.clipboard.writeText(window.location.href + '/' + id)
        alert.show(t('Alert.link_copied'), {
            timeout: 5000,
            type: 'success',
            position: cookie ? 'bottom center' : 'top center'
        })
    }
    const [vacancy, setVacancy] = useState();

    useEffect(() => {
        setVacancy(Vacancies && Vacancies.allvacancy.map((item, index) => {
            return (
                <div className="row border-bottom pb-4 pt-4" key={index}>
                    <div className="col-xl-3 col-lg-6 pe-0">
                        <h3>{item.title}</h3>
                        <div className='d-flex mt-3 flex-column'>
                            <div className='d-flex  gap-2 align-items-center mb-3 fw-normal'>
                                <i className='fas fa-map-marker-alt icons-color' color={'#5d64f1'}></i>
                                {item.vacancyCities.map((i, index) => {
                                    if (index === 0)
                                        return (
                                            <div className='d-flex flex-row' key={index}>
                                                <p className='mb-0'>{i.vacancyCityName},</p>
                                                <p className='mb-0'>{i.vacancyCountryName}</p>
                                            </div>
                                        )
                                })}
                            </div>
                            <div className='me-3 type-vacancy'>
                                <p>{item.vacancyGroupName}</p>
                            </div>
                        </div>

                    </div>
                    <div className="col-xl-5 col-lg-8">
                        <p className='text-muted fst-italic fw-light lh-base mt-2 all-vacancy-line-max'>{item.description}</p>
                    </div>
                    <div className="col-xl-3 col-lg-6 d-flex flex-row align-items-center">
                        <svg width="0" height="0">
                            <linearGradient id="blue-gradient" x1="100%" y1="50%" x2="0%" y2="0%">
                                <stop stopColor="#A74AE7" offset="0%" />
                                <stop stopColor="#0085FF" offset="100%" />
                            </linearGradient>
                        </svg>
                        {/* <button className='btn-open-vacancy' onClick={() => { navigate('respond-job', { state: { respondVacancy: item } }); }} ><p className='m-0'> Открыть</p></button> */}
                        <button className='btn-open-vacancy' onClick={() => {
                            navigate(`/jobs/${item.codeVacancy}`);
                        }}><p className='m-0'>{t('AllVacancy.btn_opne')}</p></button>
                        <div className='position-relative'>
                            <div className='share-link-tooltip'>
                                <AiOutlineShareAlt size={40} style={{ fill: "url(#blue-gradient)" }}
                                    className="ms-5 cursor-pointer " />
                                <div className='tooltip-share-button'>
                                    <div className='d-flex gap-3 align-items-center'>
                                        <div className='copy-link-button rounded-5 social-link' onClick={(e) => copyCliboard(e, item.guid)}>
                                            <img src={icon_copy} alt="My Happy SVG" className='h-75' />
                                        </div>
                                        <div className='social-link'>
                                            <FacebookShareButton url={window.location.href + "/" + item.guid} quote='Команда atto-labs'
                                                hashtag='#attolabs'>
                                                <BsFacebook size={30} style={{ fill: "url(#blue-gradient)" }} />
                                            </FacebookShareButton>
                                        </div>
                                        <div className='social-link'>
                                            <TelegramShareButton url={window.location.href + "/" + item.guid} quote='Команда atto-labs'
                                                hashtag='#attolabs' title='Вакансии AttoLabs'>
                                                <BsTelegram size={30} style={{ fill: "url(#blue-gradient)" }} />
                                            </TelegramShareButton>
                                        </div>
                                        <div className='social-link'>
                                            <LinkedinShareButton url={window.location.href + "/" + item.guid} quote='Команда atto-labs'
                                                hashtag='#attolabs'>
                                                <BsLinkedin size={28} style={{ fill: "url(#blue-gradient)" }}
                                                    className='rounded-1 ' />
                                            </LinkedinShareButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }))
    }, [Vacancies.allvacancy])

    return (
        <div>
            <div className='all-vacancy-section'>
                <div className="container h-100">
                    <div className="row h-100 text-white d-flex align-items-end">
                        <h1 className='display-2'>{t('AllVacancy.vacancies')}</h1>
                    </div>
                </div>
            </div>
            <div className="container fw-semibold pb-5 ps-4">
                <div className="row pt-5 search-section">

                    <div className='col-xxl-4 col-md-6 mb-3'>
                        <p className='fs-5'>{t('AllVacancy.search_by_tags')}</p>
                        <Autocomplete
                            value={selectedOptionsGroup}
                            multiple
                            id="tags-standard"
                            options={groups}
                            getOptionLabel={(option) => option.name}
                            onChange={handleChangeGroup}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label=""
                                    placeholder=""
                                />
                            )}
                        />
                    </div>

                    <div className='col-xxl-4 col-md-6 col-12 mb-3'>
                        <p className='fs-5'>{t('AllVacancy.country')}</p>
                        <Autocomplete
                            value={selectedOptionsCountry}
                            multiple
                            id="tags-standard"
                            options={county}
                            getOptionLabel={(option) => option.name}
                            onChange={handleChangeCountry}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label=""
                                    placeholder=""
                                />
                            )}
                        />
                    </div>

                    <div className='col-xxl-4 col-md-6 col-12 mb-1'>
                        <p className='fs-5'>{t('AllVacancy.city')}</p>
                        <Autocomplete
                            value={selectedOptionsCity}
                            multiple
                            id="tags-standard"
                            options={city}
                            getOptionLabel={(option) => option.name}
                            onChange={handleChangeCity}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label=""
                                    placeholder=""
                                />
                            )}
                        />
                    </div>
                </div>
                {loading ? (<div className='loader-vacancy'> {loadred}</div>) : (Vacancies.allvacancy.length ? (vacancy) : (
                    <div><p className='text-center text-muted'>{t('AllVacancy.no_vacancies')}</p></div>))}
                <div className='d-flex justify-content-center m-4 gap-3 pagination-vacvancy'>
                    <Pagination count={Math.ceil(Vacancies.countVacancy / size)} color="primary" page={pageApi} onChange={(e, value) => setPageApi(value)} variant="outlined" shape="rounded" />
                    <select onChange={(e) => setSize(e.target.value)} className="form-select text-muted" aria-label=".form-select-lg example" disabled={take}>
                        <option value='5'>5</option>
                        <option value='10'>10</option>
                        <option value='20'>20</option>
                    </select>
                </div>
            </div>
        </div>
    );
}

export default AllVacancies
import './Main.css'
import './Rotator.css'
import React, { useEffect, useRef } from 'react'
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



function Main() {
    const navigate = useNavigate();
    const { t } = useTranslation()
    const scroll = (param) => {
        const section = document.querySelector(param);
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <div className='main-section'>
            <div className='container d-flex justify-content-center h-100' id='home'>
                <div className="row text-center">
                    <div className="col section-main-slogan mt-lg-5">
                        <div className='main_title text-white text-uppercase text-center'>
                            Guarantee of
                            <div id="resizing-h3" className="rotate">
                                <span>
                                    <div className="stage">
                                        <div className="cubespinner ps-sm-2 ps-1">
                                            <div className="face1">quality </div>
                                            <div className="face2">cost</div>
                                            <div className="face3">time</div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <h1 className='main_slogan mt-0 mt-sm-3'>Engineers for Future</h1>
                        <div className=''>
                            <button className='btn-main-service me-sm-5' onClick={() => navigate('jobs')}>{t('Main.btn_vacancy')}<BsArrowRight className='ms-2' /></button>
                            <button className='btn-main-call ' onClick={() => scroll('#feedback')}><span>{t('Main.btn_feedback')}</span></button>
                        </div>
                        <div className='box'></div>
                    </div>

                    <div className="row mb-5 section-img-partner">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <img className='mh_50 main-partner-img'
                                src={require('../../assets/images/partner/partner1.png')} alt="img" />
                            <img className='mh_45 main-partner-img'
                                src={require('../../assets/images/partner/partner2.png')} alt="img" />
                            <img className='mh_45 main-partner-img'
                                src={require('../../assets/images/partner/partner3.png')} alt="img" />
                            <img className='mh_45 main-partner-img'
                                src={require('../../assets/images/partner/partner4.png')} alt="img" />
                            <img className='mh_150 main-partner-img'
                                src={require('../../assets/images/partner/partner5.png')} alt="img" />
                        </div>
                    </div>
                    {/* <div className="scrol-down p-0">
                        <a href="#" onClick={handleScroll}><span></span>Scroll</a>
                    </div> */}
                </div>
            </div>
        </div>

    )
}

export default Main
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { BrowserRouter } from 'react-router-dom';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import './index.css'
import App from './App';
import { getGeoInfo } from './components/dataProvider';


i18n
    .use(LanguageDetector)
    .use(HttpApi)
    .use(initReactI18next)
    .init({
        // lng: "en",
        fallbackLng: "en",
        // supportedLngs: ['en', 'ru'],
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            lookupQuerystring: "lng",
            lookupLocalStorage: 'lng',
            caches: ['localStorage'],
        },
        backend: {
            loadPath: 'https://backend.attolabs.tj/locales/{{lng}}/{{ns}}.json',
          },
        interpolation: {
            escapeValue: false
        },
    });

const options = {
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    transition: transitions.SCALE
}

const loader = document.querySelector('.spinner');
const showLoader = () => loader.classList.remove('spinner--hide');
const hideLoader = () => loader.classList.add('spinner--hide');
let setloader;

getGeoInfo().then((res)=>{
    setloader = true;
    // if (res.country_name === 'Germany' || res.country_code_iso3 === 'DEU') {
    //     setloader = false;
    // } else {
    //     setloader = true
    // }
})

function RenderNothing() {
    useEffect(hideLoader, []);
    return <></>;
}
function Greeting(props) {
    const isRender = props.isGermany;
    if (isRender) {
        return <App hideLoader={hideLoader}
            showLoader={showLoader} />
    }
    return <RenderNothing />;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
setTimeout(() =>
    root.render(
        <AlertProvider template={AlertTemplate} {...options}>
            <BrowserRouter>
                {setloader && <Greeting isGermany={setloader} />}
            </BrowserRouter>
        </AlertProvider>
    ), 1000);


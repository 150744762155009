import './contact.css'
import React, { useEffect, useState } from 'react'
import logo from '../../assets/images/navbar/logo_white.png'
import { Link } from "react-router-dom";
import { BsFacebook, BsLinkedin, BsInstagram } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link as LinkScroll } from 'react-scroll';


function Contact() {
  const { t } = useTranslation();
  const [link, setLink] = useState(false);
  const location = useLocation();
  const [currentYear, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    if (location.pathname.includes("jobs") && !location.pathname.includes("jobs/")) {
      setLink(false);
    }
    else if (location.pathname.includes("jobs/") || location.pathname.includes("about-us-detail") || location.pathname.includes("private-policy")) {
      setLink(false);
    } else {
      setLink(true);
    }
  }, [location]);

  return (
    <div className='section-contact pt-4' id='contact'>
      <div className="container text-white">
        <div className="row contact-content">
          <div className="col-xxl-2 mt-4 mb-xl-5">
            <a href='/' className='d-flex align-items-center logo-foot'>
              <img src={logo} alt='logo' className='logo-footer' />
              <p className='atto'>Atto<span className='labs'>Labs</span></p>
            </a>
            <div className='d-none d-xl-flex flex-column fs-5 mt-4 text-height-2 navigate-contact fw-light'>
              {
                link ?
                  <LinkScroll to="home" spy={true} smooth={true} offset={-250} duration={100}  > {t('Contact.btn_home')}</LinkScroll> :
                  <Link to="/#home" className={location.hash == 'home' ? 'active' : ''}  > {t('Contact.btn_home')}</Link>
              }
              {
                link ?
                  <LinkScroll to="about" spy={true} smooth={true} offset={-250} duration={100}  > {t('Navbar.about_us')} </LinkScroll> :
                  <Link to="/#about" className={location.hash == 'about' ? 'active' : ''}  >{t('Navbar.about_us')} </Link>
              }
              {
                link ?
                  <LinkScroll to="vacancy" spy={true} smooth={true} offset={-200} duration={100} >{t('Navbar.vacancy')}</LinkScroll> :
                  <Link to="/#vacancy" className={location.hash == 'vacancy' ? 'active' : ''} >{t('Navbar.vacancy')}</Link>
              }
              {
                link ?
                  <LinkScroll to="projects" spy={true} smooth={true} offset={-100} duration={100} >{t('Navbar.project')}</LinkScroll> :
                  <Link to="/#projects" className={location.hash == 'projects' ? 'active' : ''} >{t('Navbar.project')}</Link>
              }
            </div>
          </div>

          <div className="col-xxl-10 mt-3 mb-5 mt-md-5 p-con-l">
            <h1 className='display-6'>{t('Contact.contacts')}</h1>
            <div className="row mt-0 mt-md-5">
              <div className="col-md-3 ">
                <p className='display-5 fs-3'>AttoLabs</p>
                <div className='con-address'>
                  <p className='text-muted w-75 add-ger'><span dangerouslySetInnerHTML={{ __html: t('Contact.address_gernamy') }} /></p>
                </div>
                <div>
                  <a href="tel:+49 176 4445 0770" ><p>+49 176 4445 0770</p></a>
                  <a href="tel:+49 2238 4780 6118" ><p>+49 2238 4780 6118</p></a>
                  <a href="mailto: contact@attolabs.de"><p>contact@attolabs.de</p></a>
                </div>
              </div>
              <div className="col-md-3 mt-5 mt-md-0">
                <p className='display-5 fs-3 pe-3'>AttoLabs Tojikiston</p>
                <div className='con-address'>
                  <p className='text-muted w-75'><span dangerouslySetInnerHTML={{ __html: t('Contact.address_tajikistan') }} /></p>
                </div>
                <div>
                  <a href="tel:+992 92 7752111" ><p>+992 92 7752111</p></a>
                  <a href="tel:+992 3422 62111" ><p>+992 3422 62111</p></a>
                  <a href="mailto: hr@attolabs.de"><p>hr@attolabs.de</p></a>
                </div>
              </div>
              <div className="col-md-3 mt-5 mt-md-0">
                <p className='display-5 fs-3'>AttoLabs Uzbekiston</p>
                <div className='con-address'>
                  <p className='text-muted w-75'><span dangerouslySetInnerHTML={{ __html: t('Contact.address_uzbekiston') }} /></p>
                </div>
                <div>
                  <a href="tel:+998 93 652 87 05" ><p>+998 93 652 87 05</p></a>
                  <a href="mailto: hr@attolabs.de"><p>hr@attolabs.de</p></a>
                </div>
              </div>
              <div className="col-md-3 mt-5 mt-md-0">
                <p className='display-5 fs-3 pe-5'>AttoLabs Türkiye</p>
                <div className='con-address'>
                  <p className='text-muted w-75'>{t('Contact.address_tukey')}</p>
                </div>
                <div>
                  <a href="tel:+90 552 724 93 38" ><p>+90 552 724 93 38</p></a>
                  <a href="mailto: turkiye@attolabs.de"><p>turkiye@attolabs.de</p></a>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="row contact-content ">
          <div className="row mt-0 mt-md-5 p-0 p-md-5 ps-md-0 ">
            <div className="col-xxl-6 offset-xxl-5 ">
              <div className='ms-3 ms-sm-4'>
                <p className='fs-5'>{t('Feedback.social_networks')}</p>
                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/attolabs.de/"><BsFacebook size={20} className='ms-1 social-icon' /></a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/attolabs/"><BsLinkedin size={20} className='ms-3 social-icon' /></a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/attolabs/"><BsInstagram size={20} className='ms-3 social-icon' /></a>
              </div>
            </div>
            <div className="col-xxl-6 mt-5 mt-xxl-0">
              <p className='text-muted'>&#169; Copyright {currentYear} AttoLabs</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
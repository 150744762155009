import './respondForm.css'
import React, { useEffect, useRef, useState } from 'react'
import { Country, State, City } from 'country-state-city';
import { useAlert } from 'react-alert';
import { Trans, useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Box, TextField, Typography, Tab, MenuItem } from '@mui/material';
import { TabPanel, TabList, TabContext } from '@mui/lab';
import { useGlobalState } from '../../state';
import { sendVacancyAplication } from '../../dataProvider';


function RespondForm({ dataform }) {
    const lang = i18n.language;
    const [langId] = useGlobalState("langId");
    const { t } = useTranslation();
    const navigate = useNavigate();
    const alert = useAlert()
    const sizefileTranslate = t('RespondForm.max_9mb');
    const [fileName, setFileName] = useState(sizefileTranslate);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isChecked, setChecked] = useState(false);
    const [isPending, setisPending] = useState(false);
    const Country = require('country-state-city').Country.getAllCountries();

    const URL = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i
    const validationSchema = Yup.object().shape({
        file: Yup.mixed()
            .test("file", t('ErrorForm.select_file'), (file) => {
                if (file.length > 0)
                    return true;
                return false;
            })
            .test("fileSize", t('ErrorForm.less_9mb'), (value) => {
                return value.length && value[0].size <= 9437184;
            })
            .test('type', t('ErrorForm.only_support'), (value) => {
                return value.length && ["image/jpeg", "image/png", "image/jpg", "image/gif", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", ".doc", ".docx", ".odt", "application/pdf", ".rtf", ".tex", ".wpd | txt/*"].includes(value[0].type)
            }),
        link: Yup.string()
            .matches(URL, t('ErrorForm.valid_url')),
        firstname: Yup.string()
            .required(t('ErrorForm.required_field'))
            .matches(/^[aA-zZ-аА-яЯ]+$/, t('ErrorForm.only_alphabet'))
            .min(3, t('ErrorForm.firstname_must_be_3_more'))
            .max(20, t('ErrorForm.firstname_must_be_20_more')),
        lastname: Yup.string()
            .required(t('ErrorForm.required_field'))
            .matches(/^[aA-zZ-аА-яЯ]+$/, t('ErrorForm.only_alphabet'))
            .min(3, t('ErrorForm.lastname_must_be_3_more'))
            .max(20, t('ErrorForm.lastname_must_be_20_more')),
        phone: Yup.string()
            .required(t('ErrorForm.required_field'))
            .min(9, t('ErrorForm.phone_must_be_9_more'))
            .max(15, t('ErrorForm.phone_must_be_15_more')),
        email: Yup.string()
            .required(t('ErrorForm.required_field'))
            .email(t('ErrorForm.incorrect_email')),
        country: Yup.string()
            .required(t('ErrorForm.required_field')),
        city: Yup.string(),
        // .matches(/^[aA-zZ-аА-яЯ\s]+$/, t('ErrorForm.only_alphabet')),
        message: Yup.string()
            .max(100, t('ErrorForm.message_must_be_100_more'))
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
        clearErrors();
    }, [lang])

    const onSubmit = async (data) => {
        const vacancyId = dataform.vacancyId;
        const vacancyTitle = dataform.vacancyName;
        const vacancyCity = dataform.vacancyCity && dataform.vacancyCity.map(el => el.vacancyCityName).join('-');
        const countyName = require('country-state-city').Country.getCountryByCode(data.country).name;
        setisPending(true)
        let formData = new FormData();
        formData.append('vacancyId', vacancyId);
        formData.append('vacancyTitle', vacancyTitle);
        formData.append('vacancyCity', vacancyCity);
        formData.append('link', data.link ? data.link : "");
        formData.append('files', data.file[0]);
        formData.append('firstName', data.firstname);
        formData.append('lastName', data.lastname);
        formData.append('email', data.email);
        formData.append('phone', data.phone);
        formData.append('country', countyName);
        formData.append('city', data.city);
        formData.append('message', data.message);
        formData.append('lang', lang);
        formData.append('langId', langId);
        await sendVacancyAplication("vacancy-application", { formData })
            .then((res) => {
                reset();
                setFileName(sizefileTranslate)
                setisPending(false);
                onCheckboxClick();
                alert.show(t('Alert.form_sent'), {
                    timeout: 5000,
                    type: 'success',
                })
            }).catch(function () {
                alert.show(t('Alert.server_error'), {
                    timeout: 5000,
                    type: 'error',
                });
                reset();
                setFileName('max. 9 МБ')
                setisPending(false);
                onCheckboxClick();
            });
    }

    //tabcontroll 
    const [tabValue, setTabValue] = useState('1');
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // checkbox check
    const Igree = () => {
        return isChecked ? setIsDisabled(true) : setIsDisabled(false);
    };
    const onCheckboxClick = () => {
        setChecked(!isChecked);
        return Igree();
    };

    return (
        <form className='container respond-from' onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col">
                    <div className='text-white respond-contact-us'>
                        <p className='fs-4 mb-0'>{t('RespondForm.apply_for_job')}</p>
                        <p className='fw-light fst-italic m-0 small'>{t('RespondForm.we_will_respond')}</p>
                    </div>
                </div>
            </div>

            <div className="row pt-3">
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} aria-label="lab API tabs example" >
                            <Tab label={t('RespondForm.resume_file')} value="1" />
                            <Tab label={t('RespondForm.link')} value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ pb: 0, pb: 0 }}>
                        <div className='row align-items-center'>
                            <div className='col-6'>
                                <label htmlFor="files" className="btn-upload">{t('RespondForm.attach_file')}</label>
                                <input id="files" style={{ visibility: 'hidden', width: '1px' }} type="file"
                                    {...register('file', {
                                        onChange: (e) => { setFileName(getValues('file').length && getValues('file')[0].name) },
                                    })} />
                            </div>
                            <div className="col-6">
                                <p className='fw-bold  mb-0 file-error'>{fileName}</p>
                            </div>
                            <Typography sx={{ fontSize: '13px', color: 'red', pt: 1.5 }}>
                                {errors.file?.message}
                            </Typography>
                        </div >
                    </TabPanel >
                    <TabPanel value="2" sx={{ pb: 1, pt: 1 }}>
                        <div className=''>
                            <TextField
                                className='input-style'
                                variant='standard'
                                id="link"
                                name="link"
                                label={t('RespondForm.link')}
                                margin="dense"
                                {...register('link')}
                                error={errors.link ? true : false}
                            />
                            <Typography sx={{ fontSize: '13px', color: 'red' }}>
                                {errors.link?.message}
                            </Typography>
                        </div>
                    </TabPanel>
                </TabContext >
            </div >

            <div className="row" >
                <div className="col">
                    <div className='input-style-div'>
                        <TextField
                            className='input-style'
                            variant='standard'
                            id="firstname"
                            name="firstname"
                            label={t('RespondForm.placeholder_firstname')}
                            margin="dense"
                            {...register('firstname')}
                            error={errors.firstname ? true : false}
                        />
                        <Typography sx={{ fontSize: '13px', color: 'red' }}>
                            {errors.firstname?.message}
                        </Typography>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className='input-style-div'>
                        <TextField
                            className='input-style'
                            variant='standard'
                            id="lastname"
                            name="lastname"
                            label={t('RespondForm.placeholder_lastname')}
                            margin="dense"
                            {...register('lastname')}
                            error={errors.lastname ? true : false}
                        />
                        <Typography sx={{ fontSize: '13px', color: 'red' }}>
                            {errors.lastname?.message}
                        </Typography>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className='input-style-div'>
                        <TextField
                            className='input-style'
                            variant='standard'
                            id="email"
                            name="email"
                            label={t('RespondForm.placeholder_your_email')}
                            margin="dense"
                            {...register('email')}
                            error={errors.email ? true : false}
                        />
                        <Typography sx={{ fontSize: '13px', color: 'red' }}>
                            {errors.email?.message}
                        </Typography>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className='input-style-div'>
                        <TextField
                            className='input-style'
                            variant='standard'
                            type="number"
                            id="phone"
                            name="phone"
                            label={t('RespondForm.placeholder_tel')}
                            {...register('phone')}
                            error={errors.phone ? true : false}
                        />
                        <Typography sx={{ fontSize: '13px', color: 'red' }}>
                            {errors.phone?.message}
                        </Typography>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className='input-style-div'>
                        <TextField
                            select
                            id="country"
                            name="country"
                            variant='standard'
                            defaultValue=""
                            label={t('RespondForm.placeholder_country')}
                            {...register('country')}
                            error={errors.country ? true : false}
                        >
                            {Country.map((item, index) => (
                                <MenuItem key={index} value={item.isoCode}>{item.name}</MenuItem>
                            ))}
                        </TextField>
                        <Typography sx={{ fontSize: '13px', color: 'red' }}>
                            {errors.country?.message}
                        </Typography>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className='input-style-div'>
                        <TextField
                            className='input-style'
                            variant='standard'
                            id="city"
                            name="city"
                            label={t('RespondForm.placeholder_city')}
                            margin="dense"
                            {...register('city')}
                            error={errors.city ? true : false}
                        />
                        <Typography sx={{ fontSize: '13px', color: 'red' }}>
                            {errors.city?.message}
                        </Typography>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className='input-style-div'>
                        <TextField
                            className='input-style'
                            variant='standard'
                            id="message"
                            name="message"
                            label={t('RespondForm.placeholder_your_message')}
                            margin="dense"
                            {...register('message')}
                            error={errors.message ? true : false}
                        />
                        <Typography sx={{ fontSize: '13px', color: 'red' }}>
                            {errors.message?.message}
                        </Typography>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col ms-sm-3">
                    <div className='check-apply ms-0 ms-sm-3'>
                        <label className="custom-checkbox text-center text-sm-start">
                            <input type="checkbox" checked={isChecked} onChange={onCheckboxClick} />
                            <span style={{ fontSize: "13px" }} className='justify-content-center'><Trans i18nKey="Feedback.i_agree">
                                <a className="privacy-policy" onClick={() => navigate('../private-policy')}></a>
                            </Trans></span>
                        </label>
                    </div>
                    <div className="mb-3 d-flex justify-content-center justify-content-sm-start">
                        {!isPending &&
                            <button disabled={isDisabled} className="btn-submit" align='center' id="button"><p
                                className='m-0'> {t('RespondForm.send')} </p></button>}
                        {isPending && <button disabled={isDisabled} className="btn-submit" id="button"><p
                            className='m-0'> {t('RespondForm.loading')} </p></button>}
                    </div>
                </div>
            </div>
        </form >
    )
}

export default RespondForm
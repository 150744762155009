import './respondVacancy.css'
import React, { useEffect, useState } from 'react'
import icon_copy from './copy-link.svg';
import RespondForm from '../RespondForm/RespondForm';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BsFacebook, BsTelegram, BsLinkedin } from "react-icons/bs";
import { useAlert } from 'react-alert'
import {
    FacebookShareButton,
    TelegramShareButton,
    LinkedinShareButton,
} from "react-share";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useGlobalState } from '../../state';
import { getVacancyByCode } from '../../dataProvider';

function RespondVacancy(props) {
    const { t } = useTranslation();
    const alertMessageLinqCopied = t('Alert.link_copied');
    const [vacancy, setVacancy] = useState([]);
    const params = useParams();
    const lang = i18n.language;
    const [langId] = useGlobalState("langId");
    useEffect(() => {
        getVacancyByCode("vacancycode", { codeVacancy: params.codeVacancy, langId }).then((res) => {
            setVacancy(res);
        });
    }, [lang]);

    const alert = useAlert()
    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({ behavior: "smooth" })
            }
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        }
    }, [location,]);

    const copyCliboard = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(window.location.href)
        alert.show(alertMessageLinqCopied, {
            timeout: 5000,
            type: 'success',
        })
    }

    const scroll = (param) => {
        const section = document.querySelector(param);
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    return (
        <div>
            <div className="container">
                <div className="row ms-1 mt-local">
                    <div className='d-flex p-0'>
                        <h2>{vacancy.title}</h2>
                    </div>
                </div>
                <div className="row mt-3 justify-content-sm-between">
                    <div className="col-xxl-7">
                        <div>
                            <div className='d-flex gap-3 align-items-center mb-3 ms-2 fw-normal'>
                                <i className='fas fa-map-marker-alt icons-color align-self-start mt-1' color={'#5d64f1'}></i>
                                <div className='d-flex gap-3 flex-wrap'>
                                    {vacancy.vacancyCities && vacancy.vacancyCities.map((i, index) => {
                                        // if (index === 0)
                                        return (
                                            <p className='mb-0' key={index}>{i.vacancyCityName},{i.vacancyCountryName}</p>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='ms-3 ms-sm-0'>
                            <div className='mb-sm-5 mb-3'>
                                <div className='border-bottom pb-1 pb-sm-3'>
                                    <p className='text-muted fst-italic fw-light lh-base mt-2'>{vacancy.description}</p>
                                </div>
                            </div>

                            <div className='d-block d-sm-none w-100 mb-4 text-center'>
                                <button className='btn-respond-vacancy' onClick={() => scroll('#respond-from')}><p
                                    className='m-0'>{t('RespondVacancy.btn_respond')}</p></button>
                            </div>
                            <div className='mb-4 mb-sm-5'>
                                <p className='fs-4'>{t('RespondVacancy.responsibilities')} </p>
                                <ul style={{ listStyleType: 'disc' }} className='fs-6 text-muted fw-light'>
                                    {vacancy.responsibilities && vacancy.responsibilities.map((i, index) => (
                                        <li className='mt-3' key={index}>{i.name}</li>))}
                                </ul>
                            </div>
                            <div className='mb-4 mb-sm-5'>
                                <p className='fs-4'>{t('RespondVacancy.requirements')}</p>
                                <ul style={{ listStyleType: 'disc' }} className='fs-6 text-muted fw-light'>
                                    {vacancy.requirements && vacancy.requirements.map((i, index) => (
                                        <li className='mt-3' key={index}>{i.name}</li>))}
                                </ul>
                            </div>
                            <div className='border-bottom pb-1 pb-sm-3'>
                                <p className='fs-5'>{t('RespondVacancy.we_offer')}</p>
                                <ul style={{ listStyleType: 'disc' }} className='fs-6 text-muted fw-light'>
                                    {vacancy.guarantees && vacancy.guarantees.map((i, index) => (
                                        <li className='mt-3' key={index}>{i.name}</li>))}
                                </ul>
                            </div>
                            <div className='mt-1'>
                                <div className='p-0 m-0'>
                                    <svg width="0" height="0">
                                        <linearGradient id="blue-gradient" x1="100%" y1="50%" x2="0%" y2="0%">
                                            <stop stopColor="#A74AE7" offset="0%" />
                                            <stop stopColor="#0085FF" offset="100%" />
                                        </linearGradient>
                                    </svg>
                                    <div className='d-flex gap-3 align-items-center  mb-5'>
                                        <div className='copy-link rounded-5 social-link' onClick={(e) => copyCliboard(e)}>
                                            <img src={icon_copy} alt="My Happy SVG" className='h-75' />
                                        </div>
                                        <div className='social-link'>
                                            <FacebookShareButton url={window.location.href} quote='Команда atto-labs'
                                                hashtag='#attolabs'>
                                                <BsFacebook size={35} style={{ fill: "url(#blue-gradient)" }} />
                                            </FacebookShareButton>
                                        </div>
                                        <div className='social-link'>
                                            <TelegramShareButton url={window.location.href} quote='Команда atto-labs'
                                                hashtag='#attolabs' title='Вакансии AttoLabs'>
                                                <BsTelegram size={35} style={{ fill: "url(#blue-gradient)" }} />
                                            </TelegramShareButton>
                                        </div>
                                        <div className='social-link'>
                                            <LinkedinShareButton url={window.location.href} quote='Команда atto-labs'
                                                hashtag='#attolabs'>
                                                <BsLinkedin size={33} style={{ fill: "url(#blue-gradient)" }}
                                                    className='rounded-1 ' />
                                            </LinkedinShareButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-4 mb-5">
                        <div id='respond-from'>
                            <RespondForm dataform={{ vacancyId: vacancy.id, vacancyName: vacancy.title, vacancyCity: vacancy.vacancyCities }} />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default RespondVacancy
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as LinkScroll } from 'react-scroll';
import './policy.css'


function PrivatePolicy(prop) {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(true);
    const privacyContent = useRef();

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
    }, [])

    const listenToScroll = () => {
        let heightToHideFrom = privacyContent.current.offsetHeight * 0.8;
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;
        if (winScroll > heightToHideFrom) {
            isVisible && setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };
    return (
        <div className='container'>
            <div className="row p-3 p-md-0">
                <div className='col-3 d-none d-md-flex'>
                    {
                        isVisible &&
                        <div style={{ paddingTop: "170px" }} className='menu-privacy'>
                            <div className='line-privacy'>
                                <div className='d-flex flex-column'>
                                    <LinkScroll to="policy-content" spy={true} smooth={true} offset={-250} duration={100}  > {t('PrivatePolicy.policy_title')} </LinkScroll>
                                    <LinkScroll to="term-of-use" spy={true} smooth={true} offset={-250} duration={100}  > {t('PrivatePolicy.policy_terms_of_use')} </LinkScroll>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="col-12 col-md-9" ref={privacyContent}>
                    <div id='policy-content'>
                        <div style={{ paddingTop: "150px" }}></div>
                        <div className='d-flex justify-content-between gap-3 flex-column flex-sm-row pb-3'>
                            <h5 className='fw-bold text-muted'>{t('PrivatePolicy.policy_title')}</h5>
                            <p className='fw-bold text-muted'> {t('PrivatePolicy.policy_date_update')} </p>
                        </div>
                        <h4 className='fw-bold ps-3'> {t('PrivatePolicy.policy_1')}</h4>
                        <h5 className='fw-bold pt-2'>{t('PrivatePolicy.policy_1_1')}</h5>
                        <p><span dangerouslySetInnerHTML={{ __html: t('PrivatePolicy.policy_1_1_1') }} /> </p>

                        <h5 className='fw-bold pt-3'>{t('PrivatePolicy.policy_1_2')}</h5>
                        <p className='m-0'><span dangerouslySetInnerHTML={{ __html: t('PrivatePolicy.policy_1_2_1') }} /> </p>
                        <p>E-mail:  <span><a href="mailto: contact@attolabs.de">contact@attolabs.de</a></span></p>

                        <h5 className='fw-bold pt-3'>{t('PrivatePolicy.policy_1_3')}</h5>
                        <p><span dangerouslySetInnerHTML={{ __html: t('PrivatePolicy.policy_1_3_1') }} />  </p>

                        <h5 className='fw-bold pt-3'>{t('PrivatePolicy.policy_1_4')}</h5>
                        <p><span dangerouslySetInnerHTML={{ __html: t('PrivatePolicy.policy_1_4_1') }} /> </p>

                        <h5 className='fw-bold pt-3'>{t('PrivatePolicy.policy_1_5')} </h5>
                        <p><span dangerouslySetInnerHTML={{ __html: t('PrivatePolicy.policy_1_5_1') }} /> </p>

                        <h5 className='fw-bold pt-3'>{t('PrivatePolicy.policy_1_6')}</h5>
                        <p> <span dangerouslySetInnerHTML={{ __html: t('PrivatePolicy.policy_1_6_1') }} /></p>

                        <h5 className='fw-bold pt-3'>{t('PrivatePolicy.policy_1_7')} </h5>
                        <p><span dangerouslySetInnerHTML={{ __html: t('PrivatePolicy.policy_1_7_1') }} /></p>

                        <h5 className='fw-bold pt-3'>{t('PrivatePolicy.policy_1_8')}</h5>
                        <p><span dangerouslySetInnerHTML={{ __html: t('PrivatePolicy.policy_1_8_1') }} /> </p>

                        <h5 className='fw-bold pt-3'>{t('PrivatePolicy.policy_1_9')} </h5>
                        <p><span dangerouslySetInnerHTML={{ __html: t('PrivatePolicy.policy_1_9_1') }} />  </p>
                        <ul>
                            <li>{t('PrivatePolicy.policy_1_9_2')} </li>
                            <li>{t('PrivatePolicy.policy_1_9_3')} </li>
                            <li>{t('PrivatePolicy.policy_1_9_4')} </li>
                            <li>{t('PrivatePolicy.policy_1_9_5')} </li>
                        </ul>
                        <p>{t('PrivatePolicy.policy_1_9_6')} </p>

                        <h4 className='fw-bold ps-3'>{t('PrivatePolicy.policy_2')}</h4>
                        <h5 className='fw-bold pt-3'>{t('PrivatePolicy.policy_2_1')}</h5>
                        <p><span dangerouslySetInnerHTML={{ __html: t('PrivatePolicy.policy_2_1_1') }} /> </p>

                        <h4 className='fw-bold ps-3'>{t('PrivatePolicy.policy_3')}</h4>
                        <h5 className='fw-bold pt-3'>{t('PrivatePolicy.policy_3_1')}</h5>
                        <p><span dangerouslySetInnerHTML={{ __html: t('PrivatePolicy.policy_3_1_1') }} /> </p>

                        <h5 className='fw-bold pt-3'>{t('PrivatePolicy.policy_3_2')}</h5>
                        <p><span dangerouslySetInnerHTML={{ __html: t('PrivatePolicy.policy_3_2_1') }} /> </p>

                        <h5 className='fw-bold pt-3'>{t('PrivatePolicy.policy_3_3')}</h5>
                        <p><span dangerouslySetInnerHTML={{ __html: t('PrivatePolicy.policy_3_3_1') }} /> </p>

                        <h4 className='fw-bold ps-3'>{t('PrivatePolicy.policy_4')}</h4>
                        <h5 className='fw-bold pt-3'>{t('PrivatePolicy.policy_4_1')}</h5>
                        <p><span dangerouslySetInnerHTML={{ __html: t('PrivatePolicy.policy_4_1_1') }} /> </p>
                    </div>


                    {/* Term of use */}
                    <div id='term-of-use' className='pb-5'>
                        <div className='d-flex justify-content-between gap-2 flex-column flex-sm-row pt-4 pb-2'>
                            <h5 className='fw-bold text-muted'>{t('PrivatePolicy.policy_terms_of_use')}</h5>
                            <p className='fw-bold text-muted'> {t('PrivatePolicy.policy_date_update')} </p>
                        </div>
                        <h5 className='fw-bold pt-3'>{t('PrivatePolicy.policy_terms_1')}</h5>
                        <p><span dangerouslySetInnerHTML={{ __html: t('PrivatePolicy.policy_terms_1_1') }} /> </p>
                        <h5 className='fw-bold pt-3'>{t('PrivatePolicy.policy_terms_2')}</h5>
                        <p><span dangerouslySetInnerHTML={{ __html: t('PrivatePolicy.policy_terms_2_1') }} /> </p>
                        <h5 className='fw-bold pt-3'>{t('PrivatePolicy.policy_terms_3')}</h5>
                        <p><span dangerouslySetInnerHTML={{ __html: t('PrivatePolicy.policy_terms_3_1') }} /> </p>
                        <h5 className='fw-bold pt-3'>{t('PrivatePolicy.policy_terms_4')}</h5>
                        <p><span dangerouslySetInnerHTML={{ __html: t('PrivatePolicy.policy_terms_4_1') }} /> </p>
                        <h5 className='fw-bold pt-3'>{t('PrivatePolicy.policy_terms_5')}</h5>
                        <p><span dangerouslySetInnerHTML={{ __html: t('PrivatePolicy.policy_terms_5_1') }} /> </p>
                        <h5 className='fw-bold pt-3'>{t('PrivatePolicy.policy_terms_6')}</h5>
                        <p><span dangerouslySetInnerHTML={{ __html: t('PrivatePolicy.policy_terms_6_1') }} /> </p>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrivatePolicy;
import './vacancy.css'
import React, { useRef } from 'react';
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useGlobalState } from '../state';
import { getGeoInfo, getVacancies } from '../dataProvider';

function Vacancy() {
  const { t } = useTranslation();
  const tajikistan = t('AboutUsDetail.tajikistan');
  const uzbekistan = t('AboutUsDetail.uzbekistan');
  const germany = t('AboutUsDetail.germany');
  const turkey = t('AboutUsDetail.turkey');
  const isCountrty = [
    {
      county_code: "TJK",
      conunty_name: tajikistan
    },
    {
      county_code: "UZ",
      conunty_name: uzbekistan
    },
    {
      county_code: "DE",
      conunty_name: germany
    },
    {
      county_code: "TR",
      conunty_name: turkey
    },
  ];
  const navigate = useNavigate();
  const [county, setCountry] = useState('');
  const [countyCode, setCountryCode] = useState(null);
  const [vacancy, setVacancy] = useState([]);
  const isFirstRender = useRef(true);
  const [countCountry, setCountCountry] = useState(null);
  const lang = i18n.language;
  const [langId] = useGlobalState("langId");
  
  useEffect(() => {
    if (countyCode == "DE" || countyCode == "TR")
      i18n.changeLanguage('en');
    getGeoInfo().then((res) => {
      setCountryCode(res.country_code_iso3.toLowerCase());
    });
  }, []);

  useEffect(() => {
    const found = isCountrty.filter(v => v.county_code.toLowerCase() == countyCode);
    setCountry(found.map(x => x.conunty_name));
  }, [countyCode, lang])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    getVacancies("vacancy-countrycode", { countyCode, langId }).then((res) => {
      setVacancy(res.results);
      setCountCountry(res.count);
      setCountCountry((state) => {
        return state;
      });
    })
  }, [countyCode, lang]);

  useEffect(() => {
    if (countCountry == 0) {
      setCountry('');
      setCountry((state) => {
        return state;
      });
    }
  }, [countCountry])

  let isDown = false;
  let startX;
  let scrollLeft;

  useEffect(() => {
    const element = document.getElementById('card-vacncy');
    element.addEventListener('wheel', (event) => {
      event.preventDefault();
      element.scrollBy({
        left: event.deltaY < 0 ? -50 : 50,
      });
    });
    element.addEventListener("mousedown", (e) => {
      isDown = true;
      startX = e.pageX - element.offsetLeft;
      scrollLeft = element.scrollLeft;
    });
    element.addEventListener('mouseleave', () => {
      isDown = false;
    });
    element.addEventListener('mouseup', () => {
      element.addEventListener('click', () => {
        isDown = false;
      })
      element.addEventListener('mousemove', (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - element.offsetLeft;
        const walk = x - startX;
        element.scrollLeft = scrollLeft - walk;
      })
    })
  }, []);


  const vacancies = vacancy && vacancy.map((item, index) => {
    return (
      <div className='' key={index}>
        <div className="card-vacancy" onClick={() => { navigate(`/jobs/${item.codeVacancy}`); }}>
          <h5>{item.title}</h5>
          <p className='my-auto' onClick={() => { navigate(`/jobs/${item.codeVacancy}`); }}>{t('Jobs.btn_more')} <span><BsArrowRight className='ms-2 mt-auto' /></span></p>
        </div>
      </div>
    )
  });

  return (
    <div className='vacancy-section text-white' id='vacancy'>
      <div className='h-100' >
        <div className="h-100 row d-flex align-items-center p-0 m-0">
          <div className='text-center mt-3'>
            <h1 className='display-5 mt-5'>{t('Jobs.join_our_team')}</h1>
          </div>
          <div >
            <svg width="0" height="0">
              <linearGradient id="blue-gradient" x1="100%" y1="50%" x2="0%" y2="0%">
                <stop stopColor="#A74AE7" offset="0%" />
                <stop stopColor="#0085FF" offset="100%" />
              </linearGradient>
            </svg>
            <p className='text-center fs-5'>
              {
                vacancy.length > 0 ? (<span> {t('Jobs.current_vacancies')} {county && <span>{t('Jobs.in')}</span>} {county}:</span>) : (t('Jobs.no_current_vacancies'))
              }
            </p>
            <div className="wrapper-vacancy-card gap-sm-4 h-100 pb-3" id='card-vacncy' >
              {vacancies}
            </div>
          </div>

          <div className="d-flex justify-content-center p-3">
            <button className='btn-vacancy ' onClick={() => navigate('jobs')}>{t('Jobs.btn_view_all')}<BsArrowRight className='ms-2' /></button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Vacancy
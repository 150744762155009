import './Navbar.css'
import React, { useRef, useState } from 'react'
import { FaBars, FaLess, FaTimes } from 'react-icons/fa'
import { Link } from "react-router-dom";
import { Link as LinkScroll } from 'react-scroll';
import logo from '../../assets/images/navbar/logo_white.png'
import { Outlet } from 'react-router'
import { useEffect } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { setGlobalState } from '../state';
import { getLanguages } from '../dataProvider';


let langvalue;
const Navbar = () => {
    const lang = localStorage.getItem('lng');
    const [languages, setLanguages] = useState([""]);
    useEffect(() => {
        getLanguages("language").then((res) => {
            const arr = [];
            let result = res;
            result.map((lang) => {
                return arr.push({ id: lang.id, value: lang.name, label: lang.name, image: lang.image });
            });
            setLanguages(arr)
        })
    }, []);

    if (lang !== null) {
        langvalue = languages.filter(i => i.value === lang)
        // setGlobalState("langId", langvalue.length && langvalue[0].id)
    }
    else {
        langvalue = languages[0];
    }
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const [changeBack, setChangeback] = useState(false);
    const [link, setLink] = useState(false);
    const [click, setClick] = useState(false)
    const [navbarSet, setNavbarSet] = useState(false);
    useEffect(() => {
        if (location.pathname.includes("jobs") && !location.pathname.includes("jobs/")) {
            setLink(false);
            setChangeback(false)
            setNavbarSet(false);
        }
        else if (location.pathname.includes("jobs/") || location.pathname.includes("about-us-detail") || location.pathname.includes("private-policy")) {
            setLink(false);
            setChangeback(true);
            setNavbarSet(true);
        } else {
            setLink(true);
            setChangeback(false)
            setNavbarSet(false);
        }
    }, [location]);

    const handleClick = () => {
        setClick(!click);
        setNavbarSet(!false);
    }

    const closeMenu = () => {
        setClick(false);
    }
    let prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
        let currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
            document.getElementById("navbar").style.top = "0";
            // document.getElementById("element-navbar").style.paddingTop = "0";
        } else {
            // document.getElementById("navbar").style.top = "-10px";
            // document.getElementById("element-navbar").style.paddingTop = "10px";
        }
        prevScrollpos = currentScrollPos;
    }
    const changeBackground = () => {
        if (click) {
            setNavbarSet(true);
        }
        else if (changeBack) {
            setNavbarSet(true);
        }
        else if (window.scrollY >= 50) {
            setNavbarSet(true);
        } else {
            setNavbarSet(false);
        }
    }

    const changeLang = (e) => {
        i18n.changeLanguage(e.value);
        setClick(false);
        setGlobalState("langId", e.id)
    }
    window.addEventListener('scroll', changeBackground);

    return (
        <>
            <div className={navbarSet ? 'header active' : 'header'} id='navbar'>
                <div className="container h-100">
                    <nav className='navbar h-100' id='element-navbar'>
                        <a href='/' className='d-flex align-items-center' onClick={() => scroll.scrollToTop()}>
                            <img src={logo} alt='logo' className='logo-img ' id='logo-img' />
                            <p className='p-0 m-0'>Atto<span className='labs'>Labs</span></p>
                        </a>
                        <div className='hamburger' onClick={handleClick}>
                            {click ? (<FaTimes size={30} style={{ color: '#ffffff' }} />)
                                : (<FaBars size={30} style={{ color: '#ffffff' }} />)}
                        </div>
                        <ul className={click ? "nav-menu active" : "nav-menu"}>
                            <li className='nav-item fw-light'>
                                {
                                    link ?
                                        <LinkScroll to="about" spy={true} smooth={true} offset={-250} duration={100} onClick={closeMenu} > {t('Navbar.about_us')} </LinkScroll> :
                                        <Link to="/#about" className={location.hash == 'about' ? 'active' : ''} onClick={closeMenu} >{t('Navbar.about_us')} </Link>
                                }
                            </li>
                            <li className='nav-item fw-light'>
                                {
                                    link ?
                                        <LinkScroll to="projects" spy={true} smooth={true} offset={-100} duration={100} onClick={closeMenu}>{t('Navbar.project')}</LinkScroll> :
                                        <Link to="/#projects" className={location.hash == 'projects' ? 'active' : ''} onClick={closeMenu}>{t('Navbar.project')}</Link>
                                }
                            </li>
                            <li className='nav-item fw-light'>
                                {
                                    link ?
                                        <LinkScroll to="vacancy" spy={true} smooth={true} offset={-200} duration={100} onClick={closeMenu}>{t('Navbar.vacancy')}</LinkScroll> :
                                        <Link to="/#vacancy" className={location.hash == 'vacancy' ? 'active' : ''} onClick={closeMenu}>{t('Navbar.vacancy')}</Link>
                                }
                            </li>
                            <div className='tanslate-navbar'>

                                <Select
                                    isSearchable={false}
                                    menuPortalTarget={document.querySelector('body')}
                                    classNamePrefix='custom-select'
                                    value={langvalue[0]}
                                    options={languages}
                                    formatOptionLabel={country => (
                                        <div className="country-option">
                                            <img src={country.image} alt="country-image" className='img-translate' />
                                            {/* <span >{country.label}</span> */}
                                        </div>
                                    )}
                                    onChange={changeLang}
                                    closeMenuOnScroll={e => {
                                        if (e.target === document) {
                                            return true
                                        } else {
                                            return false
                                        }
                                    }}
                                />
                            </div>
                        </ul>
                    </nav>
                </div>
            </div>
            <Outlet />

        </>
    )

}

export default Navbar
